import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user/user';
import customerReducer from './slices/customer/customer';
import siteReducer from './slices/customer/site';
import orderReducer from './slices/customer/order';
import awardReducer from './slices/award/award';
import roleReducer from './slices/user/role';
import universityReducer from './slices/university/university';
import contactReducer from './slices/contact/contact';
import boardOfDirectorReducer from './slices/boardOfDirector';
import activityReducer from './slices/activity';
import projectReducer from './slices/project';

import categoryReducer from './slices/settings/category';
import typeReducer from './slices/settings/type';
import organizationReducer from './slices/settings/organization';
import regionReducer from './slices/settings/region';
import districtReducer from './slices/settings/district';
import themeReducer from './slices/settings/theme';
import newsReducer from './slices/settings/news';
import objectiveReducer from './slices/settings/objective';
import designationReducer from './slices/settings/designation';
import activityTypeReducer from './slices/settings/activityType';
import departmentReducer from './slices/settings/department';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const assetPersistConfig = {
  key: 'asset',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const organizationPersistConfig = {
  key: 'organization',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const regionPersistConfig = {
  key: 'region',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};


export const districtPersistConfig = {
  key: 'district',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};


export const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const sitePersistConfig = {
  key: 'site',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const orderPersistConfig = {
  key: 'order',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};


export const awardPersistConfig = {
  key: 'award',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const userRolesPersistConfig = {
  key: 'role',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const categoryPersistConfig = {
  key: 'category',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const typePersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const universityPersistConfig = {
  key: 'university',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const themePersistConfig = {
  key: 'theme',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const newsPersistConfig = {
  key: 'news',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const objectivePersistConfig = {
  key: 'objective',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const contactPersistConfig = {
  key: 'contact',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const designationPersistConfig = {
  key: 'designation',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const boardOfDirectorPersistConfig = {
  key: 'boardOfDirector',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const activityTypePersistConfig = {
  key: 'activityType',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const activityPersistConfig = {
  key: 'activity',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const projectPersistConfig = {
  key: 'project',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

export const departmentPersistConfig = {
  key: 'project',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error', 'initial', 'responseMessage']
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  organization: persistReducer(organizationPersistConfig, organizationReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
  site: persistReducer(sitePersistConfig, siteReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
  award: persistReducer(awardPersistConfig, awardReducer),
  role: persistReducer(userRolesPersistConfig, roleReducer),
  category: persistReducer(categoryPersistConfig, categoryReducer),
  type: persistReducer(typePersistConfig, typeReducer),
  region: persistReducer(regionPersistConfig, regionReducer),
  district: persistReducer(districtPersistConfig, districtReducer),
  university: persistReducer(universityPersistConfig, universityReducer),
  theme: persistReducer(themePersistConfig, themeReducer),
  news: persistReducer(newsPersistConfig, newsReducer),
  objective: persistReducer(objectivePersistConfig, objectiveReducer),
  contact: persistReducer(contactPersistConfig, contactReducer),
  designation: persistReducer(designationPersistConfig, designationReducer),
  boardOfDirector: persistReducer(boardOfDirectorPersistConfig, boardOfDirectorReducer),
  activityType: persistReducer(activityTypePersistConfig, activityTypeReducer),
  activity: persistReducer(activityPersistConfig, activityReducer),
  project: persistReducer(projectPersistConfig, projectReducer),
  department: persistReducer(departmentPersistConfig, departmentReducer),
});

export default rootReducer;
