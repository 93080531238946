import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  department: {},
  departments: [],
  activeDepartment: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.departments = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeDepartments = action.payload;
      state.initial = true;
    },

    // GET 
    getDepartmentSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.department = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetDepartment(state){
      state.department = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveDepartments(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeDepartments = [];
      state.initial = true;
    },

    // RESET 
    resetDepartments(state){
      state.departments = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetDepartments,
  resetActiveDepartments,
  resetDepartment,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addDepartment(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        type: params.type,
        isActive: params.isActive,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}departments/`, data);
      dispatch(slice.actions.setResponseMessage('Department Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateDepartment(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        type: params.type,
        isActive: params.isActive,
        isDefault: params.isDefault,
      }
      await axios.patch(`${CONFIG.SERVER_URL}departments/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Department updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getDepartments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}departments`);
      dispatch(slice.actions.getDepartmentsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Departments loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveDepartment() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}departments`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveDepartmentsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Departments loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getDepartment(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}departments/${id}`);
      dispatch(slice.actions.getDepartmentSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDepartment(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}departments/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetDepartment());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
