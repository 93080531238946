import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,

  // Dashboard
  Dashboard,

  // // Customer
  // CustomerList,
  // CustomerAdd,
  // CustomerEdit,
  // CustomerView,

  // SITE REPORTS
  // SiteList,

  //  SETTINGS
  Setting,

  // ----------------------------------------------------------------

  // SECURITY USER
  UserList,
  UserAdd,
  UserEdit,
  UserView,
  
  // SECURITY SETTIGS ROLES
  RoleList,
  RoleAdd,
  RoleEdit,

  // ----------------------------------------------------------------

  // MAIN
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  ErrorPage,
  BlankPage,
  PermissionDeniedPage,
  
  CategoryList,
  CategoryAdd,
  CategoryEdit,
  CategoryView,

  OrganizationList,
  OrganizationAdd,
  OrganizationEdit,
  OrganizationView,

  DepartmentList,
  DepartmentAdd,
  DepartmentEdit,
  DepartmentView,

  RegionList,
  RegionAdd,
  RegionEdit,
  RegionView,

  DistrictList,
  DistrictAdd,
  DistrictEdit,
  DistrictView,

  // OrderList,
  // OrderAdd,
  // OrderEdit,
  // OrderView,

  AwardList,
  AwardAdd,
  AwardEdit,
  AwardView,

  UniversityList,
  UniversityAdd,
  UniversityEdit,
  UniversityView,

  ContactList,
  ContactAdd,
  ContactEdit,
  ContactView,

  BoardOfDirectorList,
  BoardOfDirectorAdd,
  BoardOfDirectorEdit,
  BoardOfDirectorView,

  ActivityTypeList,
  ActivityTypeAdd,
  ActivityTypeEdit,
  ActivityTypeView,


  ActivityList,
  ActivityAdd,
  ActivityEdit,
  ActivityView,

  ProjectList,
  ProjectAdd,
  ProjectEdit,
  ProjectView,

  ThemeList,
  ThemeAdd,
  ThemeEdit,
  ThemeView,

  DesignationList,
  DesignationAdd,
  DesignationEdit,
  DesignationView,

  NewsList,
  NewsAdd,
  NewsEdit,
  NewsView,

  ObjectiveList,
  ObjectiveAdd,
  ObjectiveEdit,
  ObjectiveView,

} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      // Auth
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password/:token/:userId', element: <NewPasswordPage /> }, 
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // ----------------------------- Main Routes ----------------------------------

    {
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ],
    },
    { element: <SimpleLayout />},
    { path: '500', element: <Page500 /> },
    { path: '403', element: <Page403 /> },
    { path: '404', element: <Page404 /> },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: 'invalidErrorPage', element: <ErrorPage title='Invalid Code' /> },
        { path: 'expiredErrorPage', element: <ErrorPage title='Invitation Expired' /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },

    // --------------------- Dashboard ----------------------
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [ { element: <Dashboard />, index: true } ]
    },

    {
      path: 'projects',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ProjectList />, index: true },
        { path: 'new', element: <ProjectAdd /> },
        { path: ':id/edit', element: <ProjectEdit />},
        { path: ':id/view', element: <ProjectView />},
      ],
    },

    {
      path: 'activities',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ActivityList />, index: true },
        { path: 'new', element: <ActivityAdd /> },
        { path: ':id/edit', element: <ActivityEdit />},
        { path: ':id/view', element: <ActivityView />},
      ],
    },

    
    // SECURITY
    {
      path: 'security',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UserList />, index: true },
        {
          path: 'user',
          children: [
            { path: 'new', element: <UserAdd /> },
            { path: 'invite', element: <UserAdd isInvite /> },
            { path: ':id/edit', element: <UserEdit /> },
            { path: ':id/view', element: <UserView /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    {
      path: 'boardOfDirectors',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <BoardOfDirectorList />, index: true },
        { path: 'new', element: <BoardOfDirectorAdd /> },
        { path: ':id/edit', element: <BoardOfDirectorEdit />},
        { path: ':id/view', element: <BoardOfDirectorView />},
      ],
    },

    {
      path: 'awards',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <AwardList />, index: true },
        { path: 'new', element: <AwardAdd /> },
        { path: ':id/edit', element: <AwardEdit />},
        { path: ':id/view', element: <AwardView />},
      ],
    },

    {
      path: 'universities',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UniversityList />, index: true },
        { path: 'new', element: <UniversityAdd /> },
        { path: ':id/edit', element: <UniversityEdit />},
        { path: ':id/view', element: <UniversityView />},
      ],
    },

    {
      path: 'contacts',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ContactList />, index: true },
        { path: 'new', element: <ContactAdd /> },
        { path: ':id/edit', element: <ContactEdit />},
        { path: ':id/view', element: <ContactView />},
      ],
    },

    // ----------------------------- SETTING -----------------------------------
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {element: <Setting  />, index: true },
        // ------------------------------ role ----------------------------------
        {
          path: 'role',
          children: [
            { path: 'list', element: <RoleList /> },
            { path: 'new', element: <RoleAdd /> },
            { path: ':id/edit', element: <RoleEdit />},
          ],
        },
        {
          path: 'category',
          children: [
            { path: 'list', element: <CategoryList /> },
            { path: 'new', element: <CategoryAdd /> },
            { path: ':id/edit', element: <CategoryEdit />},
            { path: ':id/view', element: <CategoryView />}
          ],
        },
        {
          path: 'organization',
          children: [
            { path: 'list', element: <OrganizationList /> },
            { path: 'new', element: <OrganizationAdd /> },
            { path: ':id/edit', element: <OrganizationEdit />},
            { path: ':id/view', element: <OrganizationView />}
          ],
        },
        {
          path: 'department',
          children: [
            { path: 'list', element: <DepartmentList /> },
            { path: 'new', element: <DepartmentAdd /> },
            { path: ':id/edit', element: <DepartmentEdit />},
            { path: ':id/view', element: <DepartmentView />}
          ],
        },
        {
          path: 'region',
          children: [
            { path: 'list', element: <RegionList /> },
            { path: 'new', element: <RegionAdd /> },
            { path: ':id/edit', element: <RegionEdit />},
            { path: ':id/view', element: <RegionView />}
          ],
        },
        {
          path: 'district',
          children: [
            { path: 'list', element: <DistrictList /> },
            { path: 'new', element: <DistrictAdd /> },
            { path: ':id/edit', element: <DistrictEdit />},
            { path: ':id/view', element: <DistrictView />}
          ],
        },
        {
          path: 'theme',
          children: [
            { path: 'list', element: <ThemeList /> },
            { path: 'new', element: <ThemeAdd /> },
            { path: ':id/edit', element: <ThemeEdit />},
            { path: ':id/view', element: <ThemeView />}
          ],
        },
        {
          path: 'news',
          children: [
            { path: 'list', element: <NewsList /> },
            { path: 'new', element: <NewsAdd /> },
            { path: ':id/edit', element: <NewsEdit />},
            { path: ':id/view', element: <NewsView />}
          ],
        },
        {
          path: 'objective',
          children: [
            { path: 'list', element: <ObjectiveList /> },
            { path: 'new', element: <ObjectiveAdd /> },
            { path: ':id/edit', element: <ObjectiveEdit />},
            { path: ':id/view', element: <ObjectiveView />}
          ],
        },

        {
          path: 'designation',
          children: [
            { path: 'list', element: <DesignationList /> },
            { path: 'new', element: <DesignationAdd /> },
            { path: ':id/edit', element: <DesignationEdit />},
            { path: ':id/view', element: <DesignationView />}
          ],
        },

        {
          path: 'activityType',
          children: [
            { path: 'list', element: <ActivityTypeList /> },
            { path: 'new', element: <ActivityTypeAdd /> },
            { path: ':id/edit', element: <ActivityTypeEdit />},
            { path: ':id/view', element: <ActivityTypeView />}
          ],
        },

      ],
    },
  ]);
}
